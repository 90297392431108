<template>
    <div class="custom-dialog" v-if="isDialogOpen">
        <div class="custom-dialog-content">
            <div class="custom-dialog-text text-left">
                <img
                    :src="require(`@/assets/images/icons/ic_warning.svg`)"
                    alt="warning"
                />
                <h3>Save draft</h3>
                <p class="font-weight-thin">You won't be able to list your car until you provide all the required details and hit 'Confirm'.</p>
                <p class="font-weight-thin mt-3">Please note that your saved changes may take a few minutes to appear when you login again.</p>
                <p class="font-weight-thin mt-3">Are you sure you want to exit now?</p>
            </div>
            <div class="custom-dialog-buttons">
                <button class="custom-dialog-cancel" @click="handleCancel">No</button>
                <button class="custom-dialog-leave" @click="handleLeave">
                    <b-spinner v-if="isLoadingUpdate" label="Loading..." class="btn-loading"></b-spinner>
                    <template v-else>
                        Yes
                    </template>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'CustomDialog',
    data() {
        return {
            isLoadingUpdate: false,
        }
    },
    props: {
        isDialogOpen: {
            type: Boolean,
            required: true
        },
    },
    methods: {
        handleCancel() {
            this.$emit('close');
        },
        handleLeave() {
            if (!this.isLoadingUpdate) {
                this.isLoadingUpdate = true;
                this.$emit('save');
                setTimeout(() => {
                    this.isLoadingUpdate = false;
                    this.$router.push({ name: "top-page", query: this.$route.query });
                }, 10000);
            }
        },
    },
}
</script>
<style lang="scss">
.custom-dialog {
    z-index: 9999999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    h3 {
        font-family: Avenir Next LT W05 Regular !important;
        font-weight: 600 !important;
        font-size: 18px;
        margin-top: 15px;
        line-height: 30px !important;
    }
    p {
        margin-bottom: 4px;
        font-size: 14px;
        line-height: 20px;
    }
    button {
        margin: 0 10px;
        padding: 8px 16px;
        border-radius: 4px;
        cursor: pointer;
    }
    .custom-dialog-content {
        background-color: #fff;
        padding: 24px;
        border-radius: 8px;
        max-width: 400px;
        text-align: center;
        .custom-dialog-text {
            width: 90%;
        }
        .custom-dialog-buttons {
            display: flex;
            justify-content: center;
            margin-top: 29px;
            .custom-dialog-cancel {
                font-family: Avenir Next LT W05 Regular !important;
                color: black;
                font-weight: 600 !important;
                width: 170px;
                height: 44px;
                background-color: #ffffff;
                border: 1px solid var(--gray-300, #D0D5DD);
                outline: none !important;
                margin-left: 0 !important;
            }
            .custom-dialog-leave {
                font-family: Avenir Next LT W05 Regular !important;
                font-weight: 600 !important;
                width: 170px;
                height: 44px;
                background-color: var(--primary-color);
                color: #fff;
                border: none;
                outline: none !important;
                margin-right: 0 !important;
            }
        }
    }
}
@media screen and(max-width: 768px) {
    .custom-dialog {
        .custom-dialog-content {
            max-width: 322px !important;
        }
    }
    @media (orientation: landscape) {
        .custom-dialog {
            h3 {
                margin-top: 4px !important;
                margin-bottom: 4px !important;
            }
            .custom-dialog-content {
                max-width: 450px !important;
                padding: 20px !important;
                .custom-dialog-buttons {
                    margin-top: 14px !important;
                }
            }
        }
    }
}
</style>